(function () {
  function getPageQueryParams() {
    const queryString = window.location.search.substr(1);
    if (!queryString) {
      return {};
    }
    return queryString.split('&').reduce((map, pairString) => {
      const pairArray = pairString.split('=');
      map[pairArray[0]] = decodeURIComponent(pairArray[1]);
      return map;
    }, {});
  }
  function stringifyParams(params) {
    return Object.keys(params).map(key => {
      return `${key}=${encodeURIComponent(params[key])}`;
    }).join('&');
  }
  function getIsIframe() {
    const pageQueryParams = getPageQueryParams();
    return !!pageQueryParams.layout;
  }
  function getIsIFramePreviewUrl(pathname) {
    return pathname.includes('/iframe/preview');
  }
  function getIsPaymentPurchaseUrl(pathname) {
    return pathname.includes('/purchase/');
  }
  function getPaymentSessionId(pathname) {
    return pathname.split('/purchase/')[1];
  }
  function getPaymentPublicSessionId(pathname) {
    return pathname.split('/payments/')[1].replace('iframe/', '');
  }
  function getCookieValueByName(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieName = `${name}=`;
        if (cookie.substring(0, name.length + 1) === cookieName) {
          cookieValue = cookie.substring(name.length + 1);
          break;
        }
      }
    }
    return cookieValue;
  }
  function getUtk() {
    const pageQueryParams = getPageQueryParams();
    if (!pageQueryParams || !getIsIframe() || !pageQueryParams.parentHubspotUtk) {
      return getCookieValueByName('hubspotutk');
    }
    return pageQueryParams.parentHubspotUtk;
  }
  const utk = getUtk();
  const pageQueryParams = getPageQueryParams();
  const requestQueryParams = utk !== null && {
    hubspotUtk: utk
  };
  const stringifiedParams = requestQueryParams && requestQueryParams.hubspotUtk ? stringifyParams(requestQueryParams) : '';
  const quickFetch = window.quickFetch;
  if (getIsPaymentPurchaseUrl(window.location.pathname)) {
    const paymentSessionId = getPaymentSessionId(window.location.pathname);
    quickFetch.makeEarlyRequest('checkoutSession', {
      url: quickFetch.getApiUrl(`/payments-checkout/v1/checkout-sessions/${paymentSessionId}/public?${stringifiedParams}`),
      timeout: 15000,
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  } else if (!getIsIFramePreviewUrl(window.location.pathname)) {
    const publicId = getPaymentPublicSessionId(window.location.pathname);
    const postData = {
      customerEmail: pageQueryParams.email || null
    };
    quickFetch.makeEarlyRequest('checkoutSession', {
      url: quickFetch.getApiUrl(`/sales-checkout/v2/session-templates/public/${publicId}/payments-sessions?${stringifiedParams}`),
      data: JSON.stringify(postData),
      timeout: 15000,
      dataType: 'json',
      contentType: 'application/json',
      type: 'POST'
    });
  }
  const DEFAULT_COUNTRY_CODE = 'us';
  quickFetch.makeEarlyRequest('countryMetadata', {
    url: quickFetch.getApiUrl(`/address-meta/v1/countries/${DEFAULT_COUNTRY_CODE}/metadata`),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: 'GET'
  });
})();